@import './../../variables';

.astrology-section {
    background-color: $white;
    padding: 24px 12px;
    margin-top: 16px;
    clear: both;
    border-radius: 4px;

    .heading-title {
        font-size: $fontSize-24;
        font-family: $defaultFont, sans-serif;
        line-height: 30px;
        color: $headingColor;
        text-transform: uppercase;
        font-weight: $fontWeight-700;
        margin-bottom: 8px;
    }

    .sub-heading {
        font-size: 20px;
        font-family: $defaultFont, sans-serif;
        font-weight: $fontWeight-500;
        line-height: 24px;
        color: $defaultColor;
        margin-bottom: 32px;
    }

    p {
        font-family: $defaultFont, sans-serif;
        font-size: 15px;
        line-height: 24px;
        font-weight: $fontWeight-400;
        padding-bottom: 32px;
        color: $defaultColor;
        text-align: justify;

        &:last-child {
            padding-bottom: 0px;
        }
    }

}