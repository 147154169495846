@import "./../../variables";

main {
    position: relative;
}



.festival-tab {
    background-color: $white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .festival-tablist {
        .MuiTabs-flexContainer {
            flex-direction: row;
            flex-wrap: nowrap;
            position: relative;
            height: 51px;

            button {
                color: $black;
                font-family: $defaultFont, sans-serif;
                font-size: 16px;
                font-weight: $fontWeight-600;
                border: 1px solid rgba($black, $alpha: 0.20);
                max-width: 35%;
                flex: 0 0 33.39%;
                text-transform: capitalize;

                &.Mui-selected {
                    color: $primaryColor;
                }
            }
        }

        .MuiTabs-indicator {
            background-color: transparent;
            height: auto;
        }
    }

    .MuiTabPanel-root {
        &.festival-tabpanel {
            position: relative;
            padding: 0px;
            height: 330px;
            overflow-y: auto;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            .list-group-item {
                padding: 16px 10px;
                border-color: rgba($black, $alpha: 0.20);
                font-size: 16px;
                line-height: 19px;
                font-weight: $fontWeight-400;
                font-family: $defaultFont;
                color: $secondaryColor;
            }
        }
    }


}

.home-panchnag {
    background-color: $white;
    height: 382px;
    overflow-y: auto;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-bottom: 16px;

    .list-group-item {
        padding: 12px 10px;
        border-color: rgba($black, $alpha: 0.20);
        font-size: 16px;
        line-height: 19px;
        font-weight: $fontWeight-400;
        font-family: $defaultFont;
        color: $secondaryColor;

        ul {
            margin: 0px;
            padding: 0px;

            li {
                list-style: none;
                display: block;
                padding-bottom: 7px;
                font-size: 16px;
                line-height: 26px;
                font-weight: $fontWeight-400;
                color: $gray;

                strong {
                    color: $headingColor;
                    font-weight: $fontWeight-600;
                }

                a {
                    color: $textLink;
                    font-weight: $fontWeight-500;
                }
            }
        }
    }

    .today-panchang {
        padding: 8px 21px;
        text-transform: capitalize;
        font-family: $defaultFont, sans-serif;
        font-size: 20px !important;
        font-weight: $fontWeight-400 !important;
        line-height: 30px !important;
    }

}

@media (max-width: 992px) {
    .home-panchnag {
        height: auto;
        overflow-y: unset;
    }
}