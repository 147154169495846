/* colors */
/* Fonts */
.horoscope-tab {
  position: relative;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.horoscope-tab .nav-tabs {
  border: transparent;
}
.horoscope-tab .nav-tabs .nav-link {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 12px 10px;
  border-radius: 0px;
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
}
.horoscope-tab .nav-tabs .nav-link.active, .horoscope-tab .nav-tabs .nav-link:hover, .horoscope-tab .nav-tabs .nav-link:focus {
  background-color: #FAF4F0;
}
.horoscope-tab .tab-content {
  padding: 0px 0px 16px;
}

.horoscope-lists {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.horoscope-lists li {
  list-style: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-content: center;
  width: 110px;
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 0px;
}
.horoscope-lists li button {
  width: 100%;
}
.horoscope-lists li.active, .horoscope-lists li:hover, .horoscope-lists li:focus {
  background-color: #FAF4F0;
}

.horoscope-contents {
  padding: 16px 12px 0px;
}
.horoscope-contents .htitle-date {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}
.horoscope-contents .htitle-date h2 {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.horoscope-contents .htitle-date h6 {
  color: #FC5910;
  font-family: "Inter";
  font-weight: 600;
}
.horoscope-contents .horoscope-p {
  margin: 0px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: justify;
}
.horoscope-contents .horoscope-p button {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  color: #FC5910;
  background-color: transparent !important;
  padding: 0px 5px !important;
}

@media (max-width: 767px) {
  .horoscope-contents .htitle-date {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }
  .horoscope-contents .htitle-date h2 {
    font-size: 20px;
  }
  .horoscope-contents .htitle-date h6 {
    font-size: 14px;
  }
}/*# sourceMappingURL=horoscope.css.map */