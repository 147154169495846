/* colors */
/* Fonts */
body {
  margin: 0px;
  padding: 0px;
  font-family: "Inter", sans-serif !important;
  color: #707070;
  font-size: 15px;
  font-weight: 400;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none !important;
  color: #707070;
}

a:focus,
a:hover {
  outline: none;
}

p {
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif !important;
}

h1 {
  font-size: 24px;
}

img {
  max-width: 100%;
  height: auto;
}

.body-bg {
  background-image: url("./images//body-bg.png");
  background-repeat: repeat;
}

.bg-white {
  background-color: #fff;
}

.MuiContainer-root {
  max-width: 1408px !important;
  padding: 0px 16px !important;
}

.bg-primaryColor {
  background-color: #FC5910 !important;
}

.theme-btn, .sign-btn, .btn-submit, .card-btn, .default-btn {
  background-color: #FC5910 !important;
  color: #fff !important;
  font-family: "Inter", sans-serif;
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  text-decoration: none;
  padding: 15px 12px 15px 12px;
  border-radius: 4px;
}
.theme-btn:hover, .sign-btn:hover, .btn-submit:hover, .card-btn:hover, .default-btn:hover {
  background-color: #D34100 !important;
}

.default-btn {
  padding: 8px 16px !important;
  font-size: 14px !important;
  line-height: 22px;
  text-transform: capitalize !important;
  font-weight: 700 !important;
}

.card-btn {
  padding: 8px 12px;
  font-size: 14px !important;
  line-height: 14px !important;
}

.btn-submit {
  font-family: "Inter" !important;
  padding: 8px 16px !important;
  width: 200px;
  height: 46px;
  font-weight: 400 !important;
  text-align: center;
  text-transform: capitalize !important;
  line-height: 30px !important;
}

.sign-btn {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  padding: 8px 20px !important;
  border-radius: 4px !important;
  border: 0px !important;
}

.float-r {
  float: right;
}

.mt-16 {
  margin-top: 16px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.w-52 {
  width: 52%;
}

.clear {
  clear: both;
}

.heading {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFCFA4;
  padding: 12px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.heading .heading-title {
  color: #1E1E1E !important;
  font-family: "Inter" !important;
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 600;
}
.heading .heading-link {
  color: #FC5910;
  font-family: "Inter";
  font-size: 16px;
  line-height: 19px !important;
  font-weight: 600;
  text-decoration: underline !important;
}

.heading-2 {
  font-family: "Inter" !important;
  color: #FC5910 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.heading-wtbg {
  font-family: "Inter", sans-serif !important;
  color: #1E1E1E !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  text-align: center;
  margin-bottom: 24px !important;
  text-transform: capitalize;
}

.kundali-bgbox {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
  height: 100%;
}

.app-banner-box {
  position: relative;
}
.app-banner-box .app-banner {
  display: block;
  position: relative;
}
.app-banner-box .app-banner img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
}

.custom-breadcrumb ol li {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #1E1E1E;
}
.custom-breadcrumb ol li a {
  color: #1E1E1E;
}
.custom-breadcrumb ol li p {
  font-size: 18px;
  color: #1E1E1E;
  line-height: 30px;
}
.custom-breadcrumb ol li svg {
  font-size: 16px;
  line-height: 30px;
}

.slick-track {
  width: -moz-max-content !important;
  width: max-content !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.slick-track .slick-slide {
  padding: 0px 12px;
  max-width: 270px;
}

.slick-prev,
.slick-next {
  z-index: 9;
  background-color: #fff !important;
  color: #1E1E1E !important;
  width: 40px !important;
  height: 76px !important;
  text-align: center;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3019607843);
}
.slick-prev svg,
.slick-next svg {
  height: 100% !important;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  color: rgba(0, 0, 0, 0.4) !important;
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

@media (max-width: 767px) {
  .theme-btn, .default-btn,
  .card-btn,
  .btn-submit, .sign-btn {
    padding: 12px 12px 12px 12px;
  }
  .heading {
    padding: 8px 12px;
  }
  .heading .heading-title {
    font-size: 20px !important;
  }
  .heading .heading-link {
    font-size: 14px;
  }
  .custom-breadcrumb ol li {
    font-size: 14px;
  }
  .custom-breadcrumb ol li p {
    font-size: 14px;
  }
}/*# sourceMappingURL=index.css.map */