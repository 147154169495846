@import '../../variables';

.astrologer-sections {
    background-color: $bg-lightOrange;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .slick-track {
        margin: 0px !important;
        display: flex;
        flex-wrap: nowrap;
    }

    .slick-slide {
        padding: 12px 6px;
        max-width: 315px !important;
        width: 100% !important;
    }

    .slick-prev,
    .slick-next {
        z-index: 9;
        background-color: $white !important;
        color: $headingColor !important;
        width: 40px;
        height: 76px;
        text-align: center;
        box-shadow: 2px 2px 10px 0px #0000004D;

        svg {
            height: 100%;

        }

        &.slick-disabled {
            color: rgba($black, $alpha: 0.40) !important;
        }
    }

    .slick-prev {
        left: 0;

    }

    .slick-next {
        right: 0;
    }

    .slick-prev:before,
    .slick-next:before {

        content: "";

    }

}

.astrologer-card {
    position: relative;
    box-shadow: 2px 2px 10px 0px rgba($black, $alpha: 0.15) !important;
    background-color: $white;
    .astro-card-content {

        padding: 16px !important;

        a {
            display: flex;
            justify-content: space-between;
            gap: 13px;
        }

        .align-text {
            align-items: flex-end;
            display: flex;
            gap: 5px;
        }

        .card-body-text {
            h5 {
                margin: 0px;
                font-family: $defaultFont;
                font-size: 15px;
                line-height: 19px;
                font-weight: $fontWeight-500;
                color: $black;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            p {
                margin: 0px;
                font-size: 12px;
                line-height: 15px;
                font-weight: $fontWeight-500;
                color: $defaultColor;
                padding-bottom: 6px;
            }
        }

        .astro-price {
            display: block;
            margin-bottom: 6px;
            font-size: 10px;
            font-family: $defaultFont;
            font-weight: $fontWeight-500;
            color: $defaultColor;
            text-align: center;
        }

        .astro-btn {
            font-size: 10px;
            font-family: $defaultFont;
            color: $primaryColor !important;
            border-color: $primaryColor !important;
            padding: 8px !important;
        }
    }
}