/* colors */
/* Fonts */
.astro-video-card {
  padding: 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none !important;
}
.astro-video-card .astro-video-card-content {
  padding: 0px;
  position: relative;
}
.astro-video-card .astro-video-card-content:last-child {
  padding-bottom: 0px;
}
.astro-video-card .astro-video-card-content:hover .astro-video-cardbody .blog-card-title {
  color: #D34100;
}
.astro-video-card .astro-video-card-content .astro-video-thumb {
  position: relative;
}
.astro-video-card .astro-video-card-content .astro-video-thumb .card-overlay {
  position: absolute;
  right: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #F81615;
  text-align: center;
  width: 54px;
}
.astro-video-card .astro-video-card-content .astro-video-thumb .card-overlay svg {
  font-size: 54px;
}
.astro-video-card .astro-video-card-content .astro-video-cardbody {
  padding: 12px;
  background-color: #fff;
}
.astro-video-card .astro-video-card-content .astro-video-cardbody .blog-card-title {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  color: #1E1E1E;
  padding-bottom: 0px;
  margin: 0px;
  transition: all ease 0.6;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.astrovideo-list-wrap {
  background: #fff;
  padding: 24px 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.astrovideo-list-wrap .heading-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 24px;
  letter-spacing: 0px;
  color: #1E1E1E;
}

.modal-closebtn {
  color: #fff;
}
.modal-closebtn svg {
  color: #fff;
}
.modal-closebtn:hover svg {
  color: #000000;
}/*# sourceMappingURL=astrologyVideoList.css.map */