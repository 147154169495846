/* colors */
$primaryColor: #FC5910;
$secondaryColor: #343A40;
$bg-light: #F7F7F7;
$bg-lightOrange: #FAF4F0;
$bg-heading: #FFCFA4;
$defaultColor: #707070;
$black: #000000;
$white: #fff;
$gray: #343A40;
$headingColor: #1E1E1E;
$textLink: #D34100;
/* Fonts */
$defaultFont: 'Inter';
$defaultFontSize: 15px;
$fontSize-24: 24px;
$fontWeight-400: 400;
$fontWeight-500: 500;
$fontWeight-600: 600;
$fontWeight-700: 700;
$lineHeight: 24px;