/* colors */
/* Fonts */
.sidebar-services {
  position: relative;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.sidebar-services ul {
  margin: 0px;
  padding: 0px;
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.sidebar-services ul li {
  background-color: #FAF4F0;
  display: inline-block;
  margin: 5px 0px 0px 0px;
  height: 135px;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;
}
.sidebar-services ul li a {
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  color: #000000;
  height: 100%;
}
.sidebar-services ul li a img {
  margin: 16px auto 16px;
  text-align: center;
  display: block;
  max-width: 40px;
}
.sidebar-services ul li a span {
  display: block;
  padding: 0px 20px;
  word-break: break-word;
  text-transform: capitalize;
}

@media (max-width: 1024px) {
  .sidebar-services ul li a span {
    padding: 0px 8px;
  }
}/*# sourceMappingURL=sidebarCard.css.map */