/* colors */
/* Fonts */
.marriage-sign-box {
  background-color: #fff;
  padding: 20px 12px;
  border-radius: 4px;
}

.sign-box-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}
.sign-box-row .sign-card {
  flex-grow: 1;
  flex-basis: 200px;
  max-width: 200px;
  height: 216px;
}
.sign-box-row .sign-card label {
  width: 100%;
}
.sign-box-row .sign-card .card-input-element {
  display: none;
}
.sign-box-row .sign-card .card-input-element:checked + .card-input {
  box-shadow: 0 0 1px 1px #FC5910;
}
.sign-box-row .sign-card .card-input {
  padding: 24px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.sign-box-row .sign-card .card-input:hover {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.sign-box-row .sign-card .card-input .horo-icon-1x1 {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #1E1E1E;
  text-align: center;
  display: flex;
  padding: 16px;
  margin-bottom: 16px;
}
.sign-box-row .sign-card .card-input .horo-icon-1x1 img {
  display: block;
  width: 100%;
  height: auto;
}
.sign-box-row .sign-card .card-input .signcard-body {
  text-align: center;
}
.sign-box-row .sign-card .card-input .signcard-body h6 {
  font-size: 20px;
  color: #1E1E1E;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}
.sign-box-row .sign-card .card-input .signcard-body p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #707070;
  margin: 0px;
}/*# sourceMappingURL=marriageCompatibility.css.map */