@import "./../../variables";

.marriage-sign-box {
    background-color: $white;
    padding: 20px 12px;
    border-radius: 4px;
}

.sign-box-row {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;

    .sign-card {
        flex-grow: 1;
        flex-basis: 200px;
        max-width: 200px;
        height: 216px;

        label {
            width: 100%;
        }

        .card-input-element {
            display: none;

            &:checked+.card-input {
                box-shadow: 0 0 1px 1px $primaryColor;
            }
        }

        .card-input {
            padding: 24px;
            border-radius: 4px;
            background-color: $white;
            box-shadow: 2px 2px 10px 0px rgba($black, $alpha: 0.15);
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;

            &:hover {
                box-shadow: 0px 0px 25px 0px rgba($black, $alpha: 0.15);
                cursor: pointer;

            }

            .horo-icon-1x1 {
                width: 100px;
                height: 100px;
                border-radius: 100%;
                background-color: $headingColor;
                text-align: center;
                display: flex;
                padding: 16px;
                margin-bottom: 16px;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .signcard-body {
                text-align: center;

                h6 {
                    font-size: 20px;
                    color: $headingColor;
                    font-weight: 600;
                    line-height: 24px;
                    margin-bottom: 8px;
                }

                p {
                    font-size: 16px;
                    font-weight: $fontWeight-400;
                    line-height: 19px;
                    color: $defaultColor;
                    margin: 0px;
                }
            }
        }
    }
}