/* colors */
/* Fonts */
.kundali-tab.nav-tabs .nav-link {
  color: #000000;
}
.kundali-tab.nav-tabs .nav-link.active {
  color: #D34100;
}

.free-kundali-contents {
  padding: 16px 12px 0px;
}
.free-kundali-contents .table-responsive:nth-child(2) {
  margin-top: 13px;
}
.free-kundali-contents table thead tr th {
  border: 0px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #1E1E1E;
  line-height: 27px;
}
.free-kundali-contents table tbody {
  border: 1px solid #CED4DA;
  border-radius: 4px;
}
.free-kundali-contents table tbody tr:nth-of-type(odd) td {
  background-color: #FAF4F0 !important;
  --bs-table-bg-type: none;
}
.free-kundali-contents table tbody tr td {
  border: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #1E1E1E;
  font-weight: 500;
  line-height: 21px;
}
.free-kundali-contents table tbody tr td:empty {
  display: none;
}

@media (max-width: 767px) {
  .kundali-tab.nav-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .kundali-tab.nav-tabs .nav-link {
    padding: 12px 30px;
  }
  .free-kundali-contents .table-responsive:nth-child(2) {
    margin-top: 0px;
  }
  .free-kundali-contents table {
    margin-bottom: 0px;
  }
}/*# sourceMappingURL=kundaliDetails.css.map */