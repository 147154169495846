@import "./../../variables";

.sidebar-services {
    position: relative;
    background-color: $white;
    padding: 5px 10px;
    border-radius: 4px;

    ul {
        margin: 0px;
        padding: 0px;
        column-count: 2;
        column-gap: 10px;

        li {
            background-color: $bg-lightOrange;
            display: inline-block;
            margin: 5px 0px 0px 0px;
            height: 135px;
            overflow: hidden;
            border-radius: 4px;
            width: 100%;

            a {
                display: block;
                width: 100%;
                font-size: 16px;
                font-weight: $fontWeight-500;
                line-height: 23px;
                text-align: center;
                color: $black;
                height: 100%;

                img {
                    margin: 16px auto 16px;
                    text-align: center;
                    display: block;
                    max-width: 40px;
                }

                span {
                    display: block;
                    padding: 0px 20px;
                    word-break: break-word;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .sidebar-services {
        ul {
            li {
                a {
                    span {
                        padding: 0px 8px;
                    }
                }
            }
        }
    }
}