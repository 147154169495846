@import "./../../variables";

.htitle-date.h-details-title-date {
    h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: $fontWeight-700;
        color: $headingColor;
    }
}

.horoscope-dtl-p {
    font-size: 15px;
    font-weight: $fontWeight-400;
    line-height: 24px;
    text-align: justify;
    color: $defaultColor;
    margin-bottom: 24px;
}


.h-details-card {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
}

.danger-card {
    @extend .h-details-card;
    background-color: #FAF1F0;
    border-color: #FFA6A6;
}

.success-card {
    @extend .h-details-card;
    background-color: #E4FFEF;
    border-color: #36DA77;
}

.info-card {
    @extend .h-details-card;
    background-color: #EBF7FF;
    border-color: #98D1FA;
}

.warning-card {
    @extend .h-details-card;
    background-color: #FAF4F0;
    border-color: #FFC2A7;
}

.purple-card {
    @extend .h-details-card;
    background-color: #F4F0FF;
    border-color: #D3C3FF;
}

.h-details-cards {
    .card-header {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        border: 0px;
        padding: 8px 12px;
        font-weight: $fontWeight-600;
        font-size: 18px;
        line-height: 30px;
        color: $headingColor;

        span {
            max-width: 50px;
            font-size: 14px;
        }

    }

    .card-body {
        padding: 16px 12px;

        .card-icons {
            width: 80px;
            height: 80px;
            padding: 15px;
            float: left;
            margin-right: 12px;

            img {
                width: 100%;
                height: auto;
            }
        }

        p {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            text-align: justify;
            color: $headingColor;
            width: calc(100% - 92px);
            float: right;
        }
    }
}

.horoscope-details-about {
    background-color: $white;
    border-radius: 4px;
    padding: 24px 12px;
    font-size: 15px;
    color: $defaultColor;
    line-height: 24px;
    font-weight: $fontWeight-400;

    .details-about-wrap {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .heading-title {
        font-size: 24px;
        line-height: 30px;
        font-weight: $fontWeight-700;
        color: $headingColor;
        margin-bottom: 12px;
        text-transform: uppercase;
    }

    p {
        text-align: justify;
        font-size: 15px;
        line-height: 24px;
        font-weight: $fontWeight-400;
        color: $defaultColor;
    }

    ul {
        margin: 0px;
        padding-left: 22px;

        li {
            font-size: 15px;
            color: $defaultColor;

            &:empty {
                display: none;
            }

            strong {
                color: $headingColor;
                font-weight: $fontWeight-700;
            }
        }
    }
}

.alsocheck-box {
    background-color: $white;
    padding: 0px 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .alsocheck-col-grid {
        position: relative;

        h5 {
            font-size: 16px;
            font-weight: $fontWeight-600;
            line-height: 20px;
            color: $black;
            text-align: center;
            padding: 12px 10px;
            margin: 0px -8px;
            border-bottom: 1px solid rgba($black, $alpha: 0.20);
            border-right: 1px solid rgba($black, $alpha: 0.20);
        }

        .alsocheck-inner-box {
            padding: 16px;
            border-radius: 4px;
            box-shadow: 2px 2px 10px 0px rgba($black, $alpha: 0.15);
            margin: 12px 0px;
            text-align: center;

            h3 {
                font-size: 20px;
                font-weight: $fontWeight-500;
                line-height: 24px;
                text-align: center;
                color: $black;
                margin-bottom: 5px;
            }

            p {
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: $fontWeight-400;
                line-height: 22px;
                text-align: justify;
                color: $gray;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;

            }
        }
    }

}

.horoscope-facts-box {
    background-color: $white;
    padding: 16px 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .horoscope-facts {
        margin: 0px;
        padding-left: 16px;

        li {
            font-size: 14px;
            line-height: 19px;
            padding-bottom: 11px;
            color: $headingColor;
            font-weight: $fontWeight-400;

            strong {
                margin-right: 3px;
            }

        }
    }
}

.horoscope-details-quotes {
    background-image: url(./../../images/quote-bg.svg);
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
    border-radius: 4px;
    position: relative;

    .quote-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        width: 797px;
        max-width: 100%;

        .quote-box-text {
            position: absolute;
            left: 20px;
            right: 20px;
            padding: 40px 16px;
            top: 50%;
            transform: translateY(-50%);
            color: $white;
            text-align: center;


            img {
                width: 100%;
                height: 240px;
            }

            h1 {
                color: $white;
                font-size: 50px;
                line-height: 60px;
                font-weight: 900;
                font-family: 'Times New Roman', Times, serif !important;
                margin-bottom: 12px;
            }

            p {
                font-size: 16px;
                line-height: 23px;
                font-weight: $fontWeight-400;
                opacity: 70%;
                margin: 0px;

                &::before,
                &::after {
                    content: ("-");
                    padding: 0px 4px;
                }
            }

        }
    }
}

@media (max-width: 767px) {
    .alsocheck-box {
        .alsocheck-col-grid {
            h5 {
                border-right: 0px;
                border-top: 1px solid rgba($black, $alpha: 0.20);
            }
        }
    }

    .horoscope-details-quotes {
        height: 270px;

        .quote-box {
            position: relative;
            transform: translate(-50%, -40%);
            width: 100%;
            padding: 12px;

            img {
                width: 100%;
                height: 140px;
            }

            .quote-box-text {
                padding: 10px 16px;

                h1 {
                    font-size: 24px;
                    line-height: 26px;
                }
            }
        }
    }
}