/* colors */
/* Fonts */
.festival-details-wrap {
  background-color: #fff;
  border-radius: 4px;
  padding: 24px 12px;
}
.festival-details-wrap .synopsis {
  margin-bottom: 0px;
}
.festival-details-wrap .synopsis p {
  margin-bottom: 24px;
}
.festival-details-wrap .synopsis p:last-child {
  margin-bottom: 0px;
}

.festival-details-body {
  margin-bottom: 24px;
}
.festival-details-body .title {
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 24px;
}
.festival-details-body .title:empty {
  display: none;
}
.festival-details-body p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 400;
  text-align: justify;
  color: #000000;
}
.festival-details-body ul,
.festival-details-body ol {
  margin: 0px 0px 24px;
  padding-left: 20px;
}
.festival-details-body ul:has(li:empty),
.festival-details-body ol:has(li:empty) {
  display: none;
}
.festival-details-body ul li,
.festival-details-body ol li {
  font-size: 15px;
  line-height: 30px;
  color: #000000;
  font-weight: 400;
}
.festival-details-body ul li:empty,
.festival-details-body ol li:empty {
  display: none;
}/*# sourceMappingURL=festivalDetails.css.map */