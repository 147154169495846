@import "./../../variables";

header {
    margin: 0px;
    margin-bottom: 16px;
    padding: 8px 12px;
    height: 150px;
    position: relative;
    background-color: $white;

    .header-top {
        height: 80px;
        overflow: hidden;

        .MuiGrid-container {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}

.main-menu {
    min-height: 54px;
    margin-top: 8px;

    .menu-wrap {
        display: flex;
        align-items: center;
        max-width: calc(100% - 142px);
    }

    .navbar {
        padding: 8px 0px 0px;


        .navbar-collapse {
            z-index: 9;
            background-color: $white;
        }

        .nav-link {
            padding: 6px 12px;
            color: $black;
            font-family: $defaultFont, sans-serif;
            font-size: 14px;
            line-height: 17px;
            font-weight: $fontWeight-400;
            text-transform: capitalize;

            &:hover {
                color: $primaryColor;
                transition: all 0.5s;
            }
        }

        .dropdown-menu {
            border: 0px;

            .dropdown-item {
                color: $black;
                font-family: $defaultFont, sans-serif;
                font-size: 14px;

                &:hover,
                &:focus,
                &:active {
                    background-color: $primaryColor;
                    color: $white;
                }
            }
        }
    }
}

.search-box {
    position: relative;

    .MuiInputBase-root {
        padding-left: 16px;
        border-radius: 4px;
        background-color: $bg-light;

        &:hover {
            border-bottom: 0px;

            &::before {
                border-bottom: 0px !important;
            }
        }

        &::before {
            border-bottom: 0px;
        }

        &::after {
            border-bottom: 0px;
            border-bottom-color: transparent;
        }
    }

    .MuiInputAdornment-root {
        margin-top: 0px !important;

        svg {
            color: $primaryColor;
        }
    }

    .MuiInputBase-input {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 46px;
    }
}

.social-icons {
    display: block;
    position: relative;
    margin: 0px;
    padding: 0px;
    float: right;

    li {
        list-style: none;
        display: inline-block;
        width: 28px;
        height: 28px;
        margin-left: 12px;
        border-radius: 100%;

        &:first-child {
            margin-left: 0px;
        }

        a {
            display: block;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.header-login-btn {
    position: absolute;
    right: 16px;
    bottom: 8px;
}

@media (max-width: 992px) {
    .main-menu {
        width: 100%;

        .menu-wrap {
            max-width: 100%;
            display: block;
        }

        .navbar-toggler:focus {
            outline: none;
            box-shadow: none;
        }

        .navbar {
            .navbar-collapse {
                margin-left: -12px;
                margin-right: -28px;
                box-shadow: 0px 2px 4px -1px rgba($black, $alpha: 0.40);
            }
        }
    }

}

@media (max-width: 767px) {

    .header-login-btn {
        right: 12px;
    }
}