@import "./../../variables";
@import "./blog.scss";

.blog-list-wrap {
    @extend .blog-section;
    padding: 20px 12px;

    .heading-wtbg {
        text-align: left;
    }

    .blog-card {
        box-shadow: 3px 0px 20px 0px rgba($black, $alpha: .10) !important;

        .blog-card-content {
            .blog-cardbody {
                .blog-card-title {
                    font-weight: $fontWeight-500;
                    font-size: 14px;

                }
            }
        }
    }
}

.custom-pagination {
    position: relative;

    .MuiPagination-ul {
        justify-content: center;

        li {
            position: relative;

            button {
                border-radius: 8px;
                font-size: 14px;
                color: $defaultColor;

                &.Mui-selected {
                    background-color: transparent;
                    border: 1px solid $defaultColor;
                }
            }

            &:first-child,
            &:last-child {
                font-size: 14px;
                font-weight: $fontWeight-500;

                button {
                    background-color: $primaryColor;
                    color: $white;
                    padding: 0px 8px;
                    height: 36px;
                }
            }

            &:first-child {

                button {
                    &::after {
                        content: "Previous Page";
                        padding-left: 12px;
                    }

                    &.Mui-disabled {
                        background-color: $white;
                        color: $primaryColor;
                        opacity: 1;
                        border: 1px solid $primaryColor;

                        &::after {
                            content: "";
                            padding-left: 0px;
                        }
                    }
                }
            }

            &:last-child {
                button {
                    &::before {
                        content: "Next Page";
                        padding-right: 12px;
                    }

                    &.Mui-disabled {
                        background-color: $white;
                        color: $primaryColor;
                        opacity: 1;
                        border: 1px solid $primaryColor;

                        &::before {
                            content: "";
                            padding-right: 0px;
                        }
                    }

                }
            }
        }
    }
}