/* colors */
/* Fonts */
.compatibility-section {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 12px;
}

.compatibility .title {
  font-size: 20px;
  margin: 17px 0px 12px;
  line-height: 24px;
  color: #1E1E1E;
  font-weight: 700;
}
.compatibility:nth-child(n+1) .compatibility-box {
  border-color: #FFA6A6;
  background-color: #FAF1F0;
}
.compatibility:nth-child(n+2) .compatibility-box {
  border-color: rgba(33, 189, 96, 0.5);
  background-color: #E4FFEF;
}
.compatibility:nth-child(n+3) .compatibility-box {
  border-color: rgba(165, 134, 255, 0.5);
  background-color: #F4F0FF;
}
.compatibility:nth-child(n+4) .compatibility-box {
  border-color: rgba(62, 169, 245, 0.5);
  background-color: #EBF7FF;
}

.compatibility-box {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  background-color: #FAF1F0;
  border-width: 1px;
  border-style: solid;
  border-color: #FFA6A6;
  border-radius: 10px;
  margin-bottom: 32px;
}
.compatibility-box p {
  width: calc(100% - 100px);
  margin: 0px;
  padding-right: 40px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #707070;
}
.compatibility-box .circle-box {
  width: 100px;
  height: 100px;
  font-weight: 700;
  line-height: 27px;
}

.relationship-tip .title {
  margin-top: 0px;
}
.relationship-tip p {
  font-size: 15px;
  line-height: 24px;
  text-align: justify;
  font-weight: 400;
  color: #707070;
  margin: 0px;
}

@media (max-width: 767px) {
  .compatibility-box p {
    width: 100%;
    margin: 0px;
    padding-right: 0px;
    order: 2;
    font-size: 14px;
    text-align: justify;
  }
  .compatibility-box .circle-box {
    width: 70px;
    height: 70px;
    margin: 0 auto 16px;
  }
}/*# sourceMappingURL=marriageCompatibilityDetails.css.map */