/* colors */
/* Fonts */
.aside-app-banner-box {
  position: relative;
  background-color: #fff;
  padding: 32px 10px 32px;
  text-align: center;
  margin-top: 10px;
  border-radius: 4px;
}
.aside-app-banner-box h2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #1E1E1E;
  margin-bottom: 5px;
}
.aside-app-banner-box img {
  max-width: 100%;
  height: auto;
}
.aside-app-banner-box .banner-bottom {
  padding-top: 4px;
  position: relative;
}
.aside-app-banner-box .banner-bottom .download-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #1E1E1E;
  margin-bottom: 25px;
}
.aside-app-banner-box .banner-bottom .app-playstore-img {
  position: relative;
  bottom: 0;
}
.aside-app-banner-box .banner-bottom .app-playstore-img img {
  margin-bottom: 12px;
  box-shadow: 0px 24px 45px 0px rgba(127, 141, 163, 0.2);
  max-width: 100%;
  height: auto;
}/*# sourceMappingURL=verticalAppBanner.css.map */