/* colors */
/* Fonts */
.astrology-section {
  background-color: #fff;
  padding: 24px 12px;
  margin-top: 16px;
  clear: both;
  border-radius: 4px;
}
.astrology-section .heading-title {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  line-height: 30px;
  color: #1E1E1E;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 8px;
}
.astrology-section .sub-heading {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 24px;
  color: #707070;
  margin-bottom: 32px;
}
.astrology-section p {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 32px;
  color: #707070;
  text-align: justify;
}
.astrology-section p:last-child {
  padding-bottom: 0px;
}/*# sourceMappingURL=astrology.css.map */