@import "./../../variables";

.kundali-match-report {
    background-color: $white;
    padding: 24px 12px 0px;
    margin-top: 16px;
    clear: both;
    border-radius: 4px;

    .heading-title {
        font-size: $fontSize-24;
        font-family: $defaultFont, sans-serif;
        line-height: 30px;
        color: $headingColor;
        text-transform: uppercase;
        font-weight: $fontWeight-700;
        margin-bottom: 0px;
        text-transform: capitalize;
    }

    .kundali-matcher {
        margin: 24px auto;
        text-align: center;
        display: block;

        .bg-danger {
            background-color: $primaryColor !important;
            color: $white !important;
        }

        .badge {
            border-radius: 12px;
            font-size: 24px;
            font-weight: $fontWeight-600;
            line-height: 24px;
            padding: 12px 24px;
            letter-spacing: 0px;
        }

        span {
            display: inline-block;
            margin: 0px 16px;
        }
    }
}

#egg {
    display: flex;
    width: 336px;
    height: 295px;
    background-color: #FDFDFD26;
    -webkit-border-radius: 63px 63px 63px 63px/ 108px 108px 72px 72px;
    border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
    align-items: center;
    justify-content: center;
}

.kundali-match-table {
    position: relative;

    table {
        border: 1px solid #CED4DA;
        border-radius: 4px;
        margin: 0px;

        thead {
            tr {
                background-color: $bg-lightOrange;

                th {
                    border: 0px;
                    background-color: $bg-lightOrange;
                    color: $headingColor;
                    font-size: 14px;
                    font-weight: $fontWeight-500;
                    line-height: 17px;
                    padding: 6px 14px;

                    img {
                        height: 25px;
                        margin-right: 12px;
                        width: auto;
                    }
                }
            }
        }

        tbody {
            tr {
                border-color: #CED4DA;

                td {
                    color: $headingColor;
                    font-size: 14px;
                    font-weight: $fontWeight-500;
                    line-height: 17px;
                    padding: 10px 14px;
                    text-transform: capitalize;

                }
            }
        }
    }
}

.kundali-avakhada-table {
    margin-top: 36px;

    .title {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: $fontWeight-500;
        line-height: 20px;
        color: $black;
        opacity: 70%;
    }

    table {
        border: 1px solid #CED4DA;
        border-radius: 4px;

        thead {
            tr {
                background-color: $bg-heading;

                th {
                    background-color: $bg-heading;
                    font-weight: $fontWeight-600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $black;
                    padding: 16px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 14px;
                    font-weight: $fontWeight-500;
                    line-height: 16px;
                    color: $headingColor;
                    padding: 24px 10px;
                    border-bottom: 0px;
                }

                &:nth-of-type(odd) {
                    td {
                        background-color: $bg-lightOrange;
                        --bs-table-bg-type: none;
                    }
                }
            }

        }

        tr {

            td,
            th {
                text-align: center;
                min-width: 125px;
                vertical-align: middle;
                border-left: 1px solid #CED4DA;

            }
        }
    }
}

.dosha-title {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: $fontWeight-500;
    line-height: 20px;
    color: $black;
}

.kundali-ashtakoot {
    position: relative;

    .alert-danger {
        background-color: #FAF1F0;
        border-color: #FFA6A6;
        border-radius: 12px;
        padding: 24px;
    }

    .alert-success {
        background-color: #E4FFEF;
        border-color: #36DA77;
        border-radius: 12px;
        padding: 24px;
    }

    .asashtakoot-box {
        display: flex;
        gap: 24px;
        align-items: center;

        h5 {
            font-size: 16px;
            font-weight: $fontWeight-500;
            line-height: 20px;
            color: $black;
        }

        .CircularProgressbar {
            .CircularProgressbar-text {
                text-transform: capitalize;
            }
        }
    }
}

.astroconsult-section {
    background-color: $primaryColor;
    border-radius: 4px;
    padding: 16px 12px 0px;

    h4,
    p {
        color: $white;
    }

    h4 {
        font-size: 24px;
        font-weight: $fontWeight-600;
        line-height: 29px;
        margin-bottom: 12px;
    }

    p {
        font-size: 15px;
        font-weight: $fontWeight-400;
        line-height: 19px;
        padding-bottom: 24px;
    }
}

@media (max-width: 767px) {
    .kundali-match-report {

        .kundali-matcher {
            margin: 16px auto;

            .bg-danger {
                background-color: $primaryColor !important;
                color: $white !important;
            }

            .badge {
                border-radius: 12px;
                font-size: 24px;
                font-weight: $fontWeight-600;
                line-height: 24px;
                padding: 12px 24px;
                letter-spacing: 0px;
                display: block;
            }

            span {
                display: block;
                margin: 16px;
            }
        }
    }
}