@import "./../../variables";

.static-page-bg {
    background-color: $white;
    padding: 20px 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.static-page-box {
    h1 {
        font-size: 26px;
        font-weight: $fontWeight-700;
    }

    h2 {
        font-size: 24px;
        font-weight: $fontWeight-700;
    }

    h3 {
        font-size: 22px;
        font-weight: $fontWeight-600;
    }

    h4 {
        font-size: 20px;
        font-weight: $fontWeight-600;
    }

    h5 {
        font-size: 18px;
        font-weight: $fontWeight-500;
    }

    h6 {
        font-size: 16px;
        font-weight: $fontWeight-500;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $headingColor;
        margin-bottom: 12px;
    }

    p {
        font-size: 15px;
        line-height: 24px;
        font-weight: $fontWeight-400;
        color: $defaultColor;
        text-align: justify;
        margin-bottom: 20px;
    }

    img {
        height: auto;
        max-width: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        border-radius: 4px;
    }

    .about-us-heroImg {
        position: relative;
        width: 100%;
        height: 600px;

        .overlay {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            background-color: $headingColor;
            padding: 0px 12px;
            height: 128px;
            color: $white;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            ul {
                margin: 0px;
                padding: 0px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                height: 128px;

                li {

                    list-style: none;
                    text-align: center;
                    position: relative;
                    border-left: 1px solid $bg-light;
                    padding: 0px 50px;

                    &:first-child {
                        border-left: 0px;
                        padding-left: 0px;
                    }

                    &:last-child {
                        padding-right: 0px;
                    }

                    h3 {
                        color: $primaryColor;
                        margin-bottom: 12px;
                        padding: 0px;
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: $fontWeight-700;
                    }

                    span {
                        font-size: 16px;
                        font-weight: $fontWeight-400;
                        line-height: 16px;
                        color: $white;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .static-page-box {
        .about-us-heroImg {
            position: relative;
            width: 100%;
            height: auto;

            .overlay {
                position: relative;
                bottom: 40px;
                height: auto;
                padding: 16px;

                ul {
                    height: auto;

                    li {
                        padding: 0px 16px;

                        h3 {
                            font-size: 22px;
                            line-height: 22px;
                        }

                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .static-page-box {
        .about-us-heroImg {
            .overlay {
                ul {
                    flex-direction: row;
                    flex-wrap: nowrap;

                    li {
                        padding: 0px 8px;

                        h3 {
                            font-size: 20px;
                            line-height: 20px;
                        }

                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}