/* colors */
/* Fonts */
.festival-box-wrap, .festival-white-box {
  background-color: #fff;
  padding: 24px 12px 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.festival .festival-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #1E1E1E;
  margin-bottom: 8px;
}
.festival .festival-subheading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 24px;
}
.festival p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #707070;
  margin-bottom: 24px;
  text-align: justify;
}
.festival img {
  width: auto;
  height: auto;
  aspect-ratio: 6/7;
  -o-object-fit: contain;
     object-fit: contain;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.festival-table .table-heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: #1E1E1E;
  margin-bottom: 12px;
  margin-top: 16px;
}
.festival-table table {
  margin-bottom: 24px;
}
.festival-table table thead tr th {
  border: 0px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #1E1E1E;
  line-height: 27px;
}
.festival-table table tbody {
  border: 1px solid #CED4DA;
  border-radius: 4px;
}
.festival-table table tbody tr:nth-of-type(odd) td {
  background-color: #FAF4F0 !important;
  --bs-table-bg-type: none;
}
.festival-table table tbody tr td {
  border: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #1E1E1E;
  font-weight: 500;
  line-height: 17px;
  padding: 10px;
}
.festival-table table tbody tr td:empty {
  display: none;
}
.festival-table table tbody tr td a {
  color: #1E1E1E;
  font-weight: 500;
  text-decoration: underline !important;
}
.festival-table table tbody tr td a:hover {
  color: #D34100;
}

.festival-white-box {
  border-radius: 4px;
  padding: 12px 12px 0px;
}

.festival-calenders {
  margin-bottom: 24px;
}
.festival-calenders h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #1E1E1E;
}
.festival-calenders p {
  font-size: 15px;
  line-height: 24px;
  color: #707070;
  text-align: justify;
  font-weight: 400;
}
.festival-calenders a {
  color: #D34100;
  font-weight: 500;
  text-decoration: underline !important;
}

@media (max-width: 767px) {
  .festival .festival-heading {
    font-size: 20px;
    line-height: normal;
  }
  .festival .festival-subheading {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 16px;
  }
  .festival p {
    margin-bottom: 16px;
  }
  .festival img {
    margin-bottom: 16px;
  }
  .festival-table .table-heading {
    font-size: 18px;
    line-height: normal;
    margin-top: 0px;
  }
  .festival-table table {
    margin-bottom: 12px;
  }
  .festival-calenders {
    margin-bottom: 16px;
  }
  .festival-calenders h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .festival-calenders p {
    font-size: 14px;
  }
}/*# sourceMappingURL=festivalLists.css.map */