/* colors */
/* Fonts */
.kundali-forms {
  width: 100%;
  padding-bottom: 8px;
}
.kundali-forms .MuiInputLabel-root {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  color: #000000 !important;
  margin-bottom: 12px;
}
.kundali-forms .MuiFormControl-root {
  margin-bottom: 16px;
}
.kundali-forms .MuiFormControl-root .MuiInputBase-inputSizeSmall {
  padding: 11.5px 12px !important;
  font-size: 15px;
  color: #1E1E1E;
}
.kundali-forms .MuiOutlinedInput-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.btn-submit {
  margin-top: 8px;
  font-size: 20px !important;
}/*# sourceMappingURL=freeKundali.css.map */