@import './../../variables';

.astro-video-card {
    padding: 0px;
    border: 1px solid rgba($black, $alpha: 0.20);
    box-shadow: none !important;

    .astro-video-card-content {
        padding: 0px;
        position: relative;

        &:last-child {
            padding-bottom: 0px;
        }

        &:hover {
            .astro-video-cardbody {
                .blog-card-title {
                    color: $textLink;
                }
            }
        }

        .astro-video-thumb {
            position: relative;

            .card-overlay {
                position: absolute;
                right: 0;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #F81615;
                text-align: center;
                width: 54px;

                svg {
                    font-size: 54px;
                }
            }
        }

        .astro-video-cardbody {
            padding: 12px;
            background-color: $white;

            .blog-card-title {
                font-family: $defaultFont, sans-serif;
                font-size: 15px;
                line-height: 19px;
                font-weight: $fontWeight-400;
                color: $headingColor;
                padding-bottom: 0px;
                margin: 0px;
                transition: all ease 0.6;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

        }


    }

}