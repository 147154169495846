/* colors */
/* Fonts */
.htitle-date.h-details-title-date h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #1E1E1E;
}

.horoscope-dtl-p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  color: #707070;
  margin-bottom: 24px;
}

.h-details-card, .purple-card, .warning-card, .info-card, .success-card, .danger-card {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.danger-card {
  background-color: #FAF1F0;
  border-color: #FFA6A6;
}

.success-card {
  background-color: #E4FFEF;
  border-color: #36DA77;
}

.info-card {
  background-color: #EBF7FF;
  border-color: #98D1FA;
}

.warning-card {
  background-color: #FAF4F0;
  border-color: #FFC2A7;
}

.purple-card {
  background-color: #F4F0FF;
  border-color: #D3C3FF;
}

.h-details-cards .card-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 0px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #1E1E1E;
}
.h-details-cards .card-header span {
  max-width: 50px;
  font-size: 14px;
}
.h-details-cards .card-body {
  padding: 16px 12px;
}
.h-details-cards .card-body .card-icons {
  width: 80px;
  height: 80px;
  padding: 15px;
  float: left;
  margin-right: 12px;
}
.h-details-cards .card-body .card-icons img {
  width: 100%;
  height: auto;
}
.h-details-cards .card-body p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: justify;
  color: #1E1E1E;
  width: calc(100% - 92px);
  float: right;
}

.horoscope-details-about {
  background-color: #fff;
  border-radius: 4px;
  padding: 24px 12px;
  font-size: 15px;
  color: #707070;
  line-height: 24px;
  font-weight: 400;
}
.horoscope-details-about .details-about-wrap {
  margin-bottom: 40px;
}
.horoscope-details-about .details-about-wrap:last-child {
  margin-bottom: 0px;
}
.horoscope-details-about .heading-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #1E1E1E;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.horoscope-details-about p {
  text-align: justify;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #707070;
}
.horoscope-details-about ul {
  margin: 0px;
  padding-left: 22px;
}
.horoscope-details-about ul li {
  font-size: 15px;
  color: #707070;
}
.horoscope-details-about ul li:empty {
  display: none;
}
.horoscope-details-about ul li strong {
  color: #1E1E1E;
  font-weight: 700;
}

.alsocheck-box {
  background-color: #fff;
  padding: 0px 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.alsocheck-box .alsocheck-col-grid {
  position: relative;
}
.alsocheck-box .alsocheck-col-grid h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
  text-align: center;
  padding: 12px 10px;
  margin: 0px -8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.alsocheck-box .alsocheck-col-grid .alsocheck-inner-box {
  padding: 16px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
  margin: 12px 0px;
  text-align: center;
}
.alsocheck-box .alsocheck-col-grid .alsocheck-inner-box h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 5px;
}
.alsocheck-box .alsocheck-col-grid .alsocheck-inner-box p {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: justify;
  color: #343A40;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.horoscope-facts-box {
  background-color: #fff;
  padding: 16px 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.horoscope-facts-box .horoscope-facts {
  margin: 0px;
  padding-left: 16px;
}
.horoscope-facts-box .horoscope-facts li {
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 11px;
  color: #1E1E1E;
  font-weight: 400;
}
.horoscope-facts-box .horoscope-facts li strong {
  margin-right: 3px;
}

.horoscope-details-quotes {
  background-image: url(./../../images/quote-bg.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  border-radius: 4px;
  position: relative;
}
.horoscope-details-quotes .quote-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 797px;
  max-width: 100%;
}
.horoscope-details-quotes .quote-box .quote-box-text {
  position: absolute;
  left: 20px;
  right: 20px;
  padding: 40px 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  text-align: center;
}
.horoscope-details-quotes .quote-box .quote-box-text img {
  width: 100%;
  height: 240px;
}
.horoscope-details-quotes .quote-box .quote-box-text h1 {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  font-weight: 900;
  font-family: "Times New Roman", Times, serif !important;
  margin-bottom: 12px;
}
.horoscope-details-quotes .quote-box .quote-box-text p {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  opacity: 70%;
  margin: 0px;
}
.horoscope-details-quotes .quote-box .quote-box-text p::before, .horoscope-details-quotes .quote-box .quote-box-text p::after {
  content: "-";
  padding: 0px 4px;
}

@media (max-width: 767px) {
  .alsocheck-box .alsocheck-col-grid h5 {
    border-right: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .horoscope-details-quotes {
    height: 270px;
  }
  .horoscope-details-quotes .quote-box {
    position: relative;
    transform: translate(-50%, -40%);
    width: 100%;
    padding: 12px;
  }
  .horoscope-details-quotes .quote-box img {
    width: 100%;
    height: 140px;
  }
  .horoscope-details-quotes .quote-box .quote-box-text {
    padding: 10px 16px;
  }
  .horoscope-details-quotes .quote-box .quote-box-text h1 {
    font-size: 24px;
    line-height: 26px;
  }
}/*# sourceMappingURL=horoscopeDetails.css.map */