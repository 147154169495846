/* colors */
/* Fonts */
.blog-section {
  background-color: #fff;
  border-radius: 4px;
  gap: 24px;
  padding: 24px 0px;
}

.blog-card {
  padding: 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none !important;
}
.blog-card .blog-card-content {
  padding: 0px;
  position: relative;
}
.blog-card .blog-card-content:last-child {
  padding-bottom: 0px;
}
.blog-card .blog-card-content:hover .blog-cardbody .blog-card-title {
  color: #D34100;
}
.blog-card .blog-card-content img {
  aspect-ratio: 4/2.7;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: auto;
}
.blog-card .blog-card-content .blog-cardbody {
  padding: 12px;
  background-color: #fff;
}
.blog-card .blog-card-content .blog-cardbody .blog-card-title {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  text-align: justify;
  color: #1E1E1E;
  padding-bottom: 12px;
  margin: 0px;
  transition: all ease 0.6;
}
.blog-card .blog-card-content .blog-cardbody .date-author-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #707070;
}
.blog-card .blog-card-content .card-overlay {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 8px 12px;
  border-bottom-left-radius: 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #000000;
  font-weight: 600;
  line-height: 14px;
}
.blog-card .blog-card-content .card-overlay svg {
  font-weight: 400;
  font-size: 12px;
}/*# sourceMappingURL=blog.css.map */