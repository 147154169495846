/* colors */
/* Fonts */
main {
  position: relative;
}

.festival-tab {
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.festival-tab .festival-tablist .MuiTabs-flexContainer {
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: 51px;
}
.festival-tab .festival-tablist .MuiTabs-flexContainer button {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 35%;
  flex: 0 0 33.39%;
  text-transform: capitalize;
}
.festival-tab .festival-tablist .MuiTabs-flexContainer button.Mui-selected {
  color: #FC5910;
}
.festival-tab .festival-tablist .MuiTabs-indicator {
  background-color: transparent;
  height: auto;
}
.festival-tab .MuiTabPanel-root.festival-tabpanel {
  position: relative;
  padding: 0px;
  height: 330px;
  overflow-y: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.festival-tab .MuiTabPanel-root.festival-tabpanel .list-group-item {
  padding: 16px 10px;
  border-color: rgba(0, 0, 0, 0.2);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: "Inter";
  color: #343A40;
}

.home-panchnag {
  background-color: #fff;
  height: 382px;
  overflow-y: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-bottom: 16px;
}
.home-panchnag .list-group-item {
  padding: 12px 10px;
  border-color: rgba(0, 0, 0, 0.2);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: "Inter";
  color: #343A40;
}
.home-panchnag .list-group-item ul {
  margin: 0px;
  padding: 0px;
}
.home-panchnag .list-group-item ul li {
  list-style: none;
  display: block;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #343A40;
}
.home-panchnag .list-group-item ul li strong {
  color: #1E1E1E;
  font-weight: 600;
}
.home-panchnag .list-group-item ul li a {
  color: #D34100;
  font-weight: 500;
}
.home-panchnag .today-panchang {
  padding: 8px 21px;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

@media (max-width: 992px) {
  .home-panchnag {
    height: auto;
    overflow-y: unset;
  }
}/*# sourceMappingURL=homeComponents.css.map */