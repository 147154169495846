@import "./../../variables";

.festival-box-wrap {
    background-color: $white;
    padding: 24px 12px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.festival {
    .festival-heading {
        font-size: 24px;
        font-weight: $fontWeight-700;
        line-height: 30px;
        color: $headingColor;
        margin-bottom: 8px;
    }

    .festival-subheading {
        font-size: 20px;
        line-height: 24px;
        font-weight: $fontWeight-500;
        color: $defaultColor;
        margin-bottom: 24px;
    }

    p {
        font-size: 15px;
        font-weight: $fontWeight-400;
        line-height: 24px;
        color: $defaultColor;
        margin-bottom: 24px;
        text-align: justify;
    }

    img {
        width: auto;
        height: auto;
        aspect-ratio: 6/7;
        object-fit: contain;
        text-align: center;
        display: block;
        margin: 0 auto;
    }
}

.festival-table {

    .table-heading {
        font-size: 20px;
        line-height: 24px;
        font-weight: $fontWeight-600;
        color: $headingColor;
        margin-bottom: 12px;
        margin-top: 16px;
    }

    table {
        margin-bottom: 24px;

        thead {
            tr {
                th {
                    border: 0px;
                    font-family: $defaultFont, sans-serif;
                    font-weight: $fontWeight-500;
                    font-size: 18px;
                    color: $headingColor;
                    line-height: 27px;
                }
            }
        }

        tbody {
            border: 1px solid #CED4DA;
            border-radius: 4px;

            tr {
                &:nth-of-type(odd) {
                    td {
                        background-color: $bg-lightOrange !important;
                        --bs-table-bg-type: none;
                    }
                }

                td {
                    border: 0px;
                    font-family: $defaultFont, sans-serif;
                    font-size: 14px;
                    color: $headingColor;
                    font-weight: $fontWeight-500;
                    line-height: 17px;
                    padding: 10px;

                    &:empty {
                        display: none;
                    }

                    a {
                        color: $headingColor;
                        font-weight: $fontWeight-500;
                        text-decoration: underline !important;

                        &:hover {
                            color: $textLink;
                        }
                    }
                }


            }
        }

    }
}

.festival-white-box {
    @extend .festival-box-wrap;
    border-radius: 4px;
    padding: 12px 12px 0px;
}

.festival-calenders {
    margin-bottom: 24px;

    h4 {
        font-size: 20px;
        font-weight: $fontWeight-600;
        line-height: 32px;
        color: $headingColor;
    }

    p {
        font-size: 15px;
        line-height: 24px;
        color: $defaultColor;
        text-align: justify;
        font-weight: $fontWeight-400;
    }

    a {
        color: $textLink;
        font-weight: $fontWeight-500;
        text-decoration: underline !important;
    }
}

@media (max-width: 767px) {
    .festival {
        .festival-heading {
            font-size: 20px;
            line-height: normal;
        }

        .festival-subheading {
            font-size: 18px;
            line-height: normal;
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 16px;
        }

        img {
            margin-bottom: 16px;
        }
    }

    .festival-table {
        .table-heading {
            font-size: 18px;
            line-height: normal;
            margin-top: 0px;
        }

        table {
            margin-bottom: 12px;
        }
    }

    .festival-calenders {
        margin-bottom: 16px;

        h4 {
            font-size: 18px;
            line-height: 24px;
        }

        p {
            font-size: 14px;
        }
    }
}