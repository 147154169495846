@import "./../../variables";

.aside-app-banner-box {
    position: relative;
    background-color: $white;
    padding: 32px 10px 32px;
    text-align: center;
    margin-top: 10px;
    border-radius: 4px;

    h2 {
        font-size: 24px;
        line-height: 34px;
        font-weight: $fontWeight-700;
        color: $headingColor;
        margin-bottom: 5px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .banner-bottom {
        padding-top: 4px;
        position: relative;

        .download-title {
            font-size: 24px;
            line-height: 34px;
            font-weight: $fontWeight-700;
            color: $headingColor;
            margin-bottom: 25px;
        }

        .app-playstore-img {
            position: relative;
            bottom: 0;

            img {
                margin-bottom: 12px;
                box-shadow: 0px 24px 45px 0px #7F8DA333;
                max-width: 100%;
                height: auto;
            }
        }
    }
}