/* colors */
/* Fonts */
footer {
  background-color: #FAF4F0;
}

.footer {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px;
}

.footer-heading {
  position: relative;
  font-family: "Inter", sans-serif !important;
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 600 !important;
  color: #1E1E1E !important;
  padding-bottom: 10px;
  margin-bottom: 28px !important;
  text-transform: capitalize;
}
.footer-heading::before {
  content: "";
  width: 60px;
  height: 2px;
  background-color: #FC5910;
  position: absolute;
  left: 0;
  bottom: 0;
}

.f-about-dtl {
  font-size: 17px !important;
  line-height: 24px !important;
  text-align: justify;
  color: #707070 !important;
  padding-bottom: 24px;
}

.footer-logos {
  margin: 0px;
}
.footer-logos .footer-playStore {
  margin-top: 32px;
  width: 100%;
}
.footer-logos .footer-playStore img {
  margin-bottom: 12px;
  box-shadow: 0px 24px 45px 0px rgba(127, 141, 163, 0.2);
  max-width: 100%;
  height: auto;
}

.f-navbar {
  position: relative;
  width: 100%;
}
.f-navbar ul {
  margin: 0px;
  padding: 0px;
}
.f-navbar ul li {
  list-style: none;
  display: block;
  padding-bottom: 20px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #1E1E1E;
}
.f-navbar ul li span {
  width: 18px;
  margin-right: 8px;
  float: left;
}
.f-navbar ul li span svg {
  font-size: 18px;
}
.f-navbar ul li a {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #1E1E1E;
  transition: all ease 0.5s;
}
.f-navbar ul li a:hover, .f-navbar ul li a:focus {
  color: #D34100;
}

.footer-bottom {
  margin-top: 40px;
  background-color: #1E1E1E;
  padding: 16px;
  width: 100%;
}
.footer-bottom p {
  margin: 0px;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  text-align: center;
}/*# sourceMappingURL=footer.css.map */