@import "./variables";

body {
    margin: 0px;
    padding: 0px;
    font-family: $defaultFont, sans-serif !important;
    color: $defaultColor;
    font-size: $defaultFontSize;
    font-weight: $fontWeight-400;
    background-repeat: no-repeat;
    background-size: cover;
}

a {
    text-decoration: none !important;
    color: $defaultColor;
}

a:focus,
a:hover {
    outline: none;
}

p {
    font-family: $defaultFont, sans-serif !important;
    letter-spacing: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $defaultFont, sans-serif !important;
}

h1 {
    font-size: 24px;
}

img {
    max-width: 100%;
    height: auto;
}

.body-bg {
    background-image: url("./images//body-bg.png");
    background-repeat: repeat;
}

.bg-white {
    background-color: $white;
}

.MuiContainer-root {
    max-width: 1408px !important;
    padding: 0px 16px !important;
}

.bg-primaryColor {
    background-color: $primaryColor !important;
}

.theme-btn {
    background-color: $primaryColor !important;
    color: $white !important;
    font-family: $defaultFont, sans-serif;
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: $fontWeight-600 !important;
    text-decoration: none;
    padding: 15px 12px 15px 12px;
    border-radius: 4px;

    &:hover {
        background-color: $textLink !important;
    }
}

.default-btn {
    @extend .theme-btn;
    padding: 8px 16px !important;
    font-size: 14px !important;
    line-height: 22px;
    text-transform: capitalize !important;
    font-weight: $fontWeight-700 !important;
}

.card-btn {
    @extend .theme-btn;
    padding: 8px 12px;
    font-size: 14px !important;
    line-height: 14px !important;
}

.btn-submit {
    @extend .theme-btn;
    font-family: $defaultFont !important;
    padding: 8px 16px !important;
    width: 200px;
    height: 46px;
    font-weight: 400 !important;
    text-align: center;
    text-transform: capitalize !important;
    line-height: 30px !important;

}

.sign-btn {
    @extend .theme-btn;
    font-size: 20px !important;
    font-weight: $fontWeight-400 !important;
    line-height: 30px !important;
    padding: 8px 20px !important;
    border-radius: 4px !important;
    border: 0px !important;
}

.float-r {
    float: right;
}

.mt-16 {
    margin-top: 16px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.w-52 {
    width: 52%;
}

.clear {
    clear: both;
}

.heading {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $bg-heading;
    padding: 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .heading-title {
        color: $headingColor !important;
        font-family: $defaultFont !important;
        font-size: $fontSize-24 !important;
        line-height: 30px !important;
        font-weight: $fontWeight-600;
    }

    .heading-link {
        color: $primaryColor;
        font-family: $defaultFont;
        font-size: 16px;
        line-height: 19px !important;
        font-weight: $fontWeight-600;
        text-decoration: underline !important;
    }
}

.heading-2 {
    font-family: $defaultFont !important;
    color: $primaryColor !important;
    font-size: 20px !important;
    font-weight: $fontWeight-600 !important;
}

.heading-wtbg {
    font-family: $defaultFont, sans-serif !important;
    color: $headingColor !important;
    font-size: 24px !important;
    font-weight: $fontWeight-700 !important;
    line-height: 30px !important;
    text-align: center;
    margin-bottom: 24px !important;
    text-transform: capitalize;
}

.kundali-bgbox {
    background-color: $white;
    padding: 12px;
    border-radius: 4px;
    height: 100%;
}

.app-banner-box {
    position: relative;

    .app-banner {
        display: block;
        position: relative;

        img {
            max-width: 100%;
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

// Breadcrumbs
.custom-breadcrumb {
    ol {
        li {
            font-family: $defaultFont;
            font-size: 18px;
            font-weight: $fontWeight-600;
            line-height: 30px;
            color: $headingColor;

            a {
                color: $headingColor;
            }

            p {
                font-size: 18px;
                color: $headingColor;
                line-height: 30px;
            }

            svg {
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
}

// slick carousal css


.slick-track {
    width: max-content !important;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .slick-slide {
        padding: 0px 12px;
        max-width: 270px;
    }
}

.slick-prev,
.slick-next {
    z-index: 9;
    background-color: $white !important;
    color: $headingColor !important;
    width: 40px !important;
    height: 76px !important;
    text-align: center;
    box-shadow: 2px 2px 10px 0px #0000004D;

    svg {
        height: 100% !important;

    }

    &.slick-disabled {
        color: rgba($black, $alpha: 0.40) !important;
    }
}

.slick-prev {
    left: 0 !important;

}

.slick-next {
    right: 0 !important;
}

.slick-prev:before,
.slick-next:before {

    content: "" !important;

}

@media (max-width: 767px) {

    .theme-btn,
    .btn-submit,
    .card-btn {
        padding: 12px 12px 12px 12px;
    }

    .heading {
        padding: 8px 12px;

        .heading-title {
            font-size: 20px !important;
        }

        .heading-link {
            font-size: 14px;
        }
    }

    .custom-breadcrumb {
        ol {
            li {
                font-size: 14px;

                p {
                    font-size: 14px;
                }
            }
        }
    }
}