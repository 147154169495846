@import "./../../variables";

.location-box {
    background-color: $bg-lightOrange;
    padding: 16px;
    border-radius: 4px;

    .box-heading {
        font-size: 14px;
        line-height: 17px;
        font-weight: $fontWeight-500;
        color: $headingColor;
        margin: 0px;
        padding-bottom: 12px;
    }

    form {
        display: flex;
        align-items: center;

        .MuiInputBase-root {
            padding: 0px !important;
            font-family: $defaultFont;
            border: 0px;

            .MuiInputBase-input {
                padding: 12px 16px !important;
                background-color: $white;
            }

            .MuiOutlinedInput-notchedOutline {
                border: 0px;
            }

        }

        .theme-btn {
            font-weight: $fontWeight-400 !important;
            font-size: 14px !important;
            width: 100%;
        }

    }
}

.panchang-box-heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    h4 {
        font-size: 24px;
        line-height: 29px;
        font-weight: $fontWeight-500;
        color: $headingColor;
        text-transform: capitalize;
    }

    .date,
    .locations {
        color: $primaryColor;
        font-size: 16px;
        line-height: 19px;
        font-weight: $fontWeight-600;
    }

    .date {
        &::after {
            content: "|";
            padding-left: 4px;
            padding-right: 4px;
            vertical-align: text-top;
        }
    }
}

.sunset-moonset {
    position: relative;
    text-align: center;

    h6 {
        font-size: 14px;
        font-weight: $fontWeight-400;
        color: $defaultColor;
        margin-bottom: 8px;
    }

    .alert {
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 26px;
        margin-bottom: 24px;
    }

    .alert-warning {
        background-color: #FDE8DF;
        border-color: $primaryColor;

        .sunset-icon {
            background-color: rgba($white, $alpha: 0.5);
            width: 40px;
            height: 40px;
            border-radius: 100%;
            color: $primaryColor;
            padding: 8px;
        }

    }

    .alert-info {
        background-color: #EDF2FF;
        border-color: #10A7FC;

        .moon-icon {
            background-color: rgba($white, $alpha: 0.5);
            width: 40px;
            height: 40px;
            border-radius: 100%;
            color: #10A7FC;
            padding: 8px;
        }
    }
}

.panchang-table {
    table {
        margin: 0px;
        border-radius: 4px;


        tbody {
            border-radius: 4px;
            border: 1px solid #CED4DA;

            tr {
                &:nth-of-type(odd) {
                    td {
                        background-color: $bg-lightOrange;
                        --bs-table-bg-type: none
                    }
                }

                td {
                    border: 0px;
                    padding: 8px 14px;
                    font-size: 14px;
                    font-weight: $fontWeight-500;
                    color: $headingColor;
                    width: 50%;
                }
            }
        }
    }
}

.muhurt-box {
    background-color: $white;
    padding: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
    clear: both;

    .muhurt-title {
        font-size: 18px;
        font-weight: $fontWeight-500;
        line-height: 21px;
        color: rgba($headingColor, $alpha: 0.7);
        padding: 20px 0px 12px;
        margin: 0px;
    }
}

.muhurt-table {
    @extend .panchang-table;

    table {
        tbody {
            tr {
                td {
                    width: auto;
                    min-width: 125px;
                    vertical-align: middle;
                }
            }
        }
    }
}

.box-align {
    align-items: center;
}

.lagan-chart {
    background-color: $white;
    padding: 25px 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center
}

.planetary-table {
    @extend .panchang-table;

    table {
        thead {
            tr {
                th {
                    text-align: center;
                    font-size: 16px;
                    font-weight: $fontWeight-600;
                    color: $black;
                    border-right: 1px solid rgba($black, $alpha: 0.20);
                }
            }
        }

        tbody {
            tr {
                td {
                    width: auto;
                    text-align: center;
                    border-right: 1px solid rgba($black, $alpha: 0.20);
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .location-box {
        form {
            .theme-btn {
                padding: 12px;
            }
        }
    }

    .sunset-moonset {
        .alert {
            &.alert-info {
                margin-bottom: 0px;
            }
        }
    }

    .muhurt-box {
        height: auto;
    }

    .muhurt-table {

        table {
            tbody {
                tr {
                    td {
                        min-width: 100px;
                    }
                }
            }
        }
    }
}