/* colors */
/* Fonts */
.blog-details-wrap {
  background-color: #fff;
  border-radius: 4px;
  padding: 24px 12px;
}
.blog-details-wrap .heading-wtbg {
  text-align: left;
}

.blog-details .hero-img {
  margin-bottom: 24px;
}
.blog-details .hero-img img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  aspect-ratio: 16/9;
  -o-object-fit: contain;
     object-fit: contain;
}
.blog-details .blog-social-wrap {
  margin-bottom: 24px;
  display: block;
}
.blog-details .blog-social-wrap .social-icons {
  float: none;
}

.synopsis {
  margin-bottom: 24px;
}
.synopsis p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #707070;
  text-align: justify;
}

.blog-app-banner {
  margin-bottom: 24px;
}
.blog-app-banner a img {
  border-radius: 4px;
  width: 100%;
  height: auto;
  aspect-ratio: 4.13/1;
  -o-object-fit: contain;
     object-fit: contain;
}

.blog-details-body .body-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  color: #000000;
}
.blog-details-body p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}
.blog-details-body .body-sub-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #000000;
}
.blog-details-body a {
  color: #D34100;
  box-shadow: #D34100 0px -1px 0px inset;
  transition: all 0.6s;
}
.blog-details-body a:hover {
  color: #FC5910;
  box-shadow: #FC5910 0px -1px 0px inset;
}/*# sourceMappingURL=blogDetails.css.map */