@import './../../variables';

.kundali-forms {
    width: 100%;
    padding-bottom: 8px;

    .MuiInputLabel-root {
        font-family: $defaultFont;
        font-size: 16px;
        font-weight: $fontWeight-600;
        color: $black !important;
        margin-bottom: 12px;
    }

    .MuiFormControl-root {
        margin-bottom: 16px;

        .MuiInputBase-inputSizeSmall {
            padding: 11.5px 12px !important;
            font-size: 15px;
            color: $headingColor;
        }


    }

    .MuiOutlinedInput-root {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.btn-submit {
    margin-top: 8px;
    font-size: 20px !important;
}