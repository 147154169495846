/* colors */
/* Fonts */
.today-deals {
  background-color: #FAF4F0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 8px;
}
.today-deals .today-deals-card {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px 0px 0px;
  margin: 0px;
  gap: 8px;
}
.today-deals .today-deals-card li {
  position: relative;
  flex: 0 0 24.32%;
  max-width: 24.32%;
  width: 100%;
  list-style: none;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 24px 12px 24px 12px;
  text-align: center;
}
.today-deals .today-deals-card li img {
  max-width: 143px;
  height: auto;
  margin-bottom: 12px;
}
.today-deals .today-deals-card li .deals-card-heading {
  font-family: "Inter";
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #000000;
  padding-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .today-deals .today-deals-card li {
    flex: 0 0 24.17%;
    max-width: 24.17%;
  }
}
@media (max-width: 932px) {
  .today-deals .today-deals-card li {
    flex: 0 0 24.08%;
    max-width: 24.08%;
  }
}
@media (max-width: 844px) {
  .today-deals .today-deals-card li {
    flex: 0 0 49.2%;
    max-width: 50%;
  }
}
@media (max-width: 540px) {
  .today-deals .today-deals-card li {
    flex: 0 0 49.1%;
    max-width: 49.1%;
  }
}
@media (max-width: 460px) {
  .today-deals .today-deals-card li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}/*# sourceMappingURL=todayDeals.css.map */