/* colors */
/* Fonts */
.location-box {
  background-color: #FAF4F0;
  padding: 16px;
  border-radius: 4px;
}
.location-box .box-heading {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #1E1E1E;
  margin: 0px;
  padding-bottom: 12px;
}
.location-box form {
  display: flex;
  align-items: center;
}
.location-box form .MuiInputBase-root {
  padding: 0px !important;
  font-family: "Inter";
  border: 0px;
}
.location-box form .MuiInputBase-root .MuiInputBase-input {
  padding: 12px 16px !important;
  background-color: #fff;
}
.location-box form .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 0px;
}
.location-box form .theme-btn {
  font-weight: 400 !important;
  font-size: 14px !important;
  width: 100%;
}

.panchang-box-heading {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.panchang-box-heading h4 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  color: #1E1E1E;
  text-transform: capitalize;
}
.panchang-box-heading .date,
.panchang-box-heading .locations {
  color: #FC5910;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}
.panchang-box-heading .date::after {
  content: "|";
  padding-left: 4px;
  padding-right: 4px;
  vertical-align: text-top;
}

.sunset-moonset {
  position: relative;
  text-align: center;
}
.sunset-moonset h6 {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  margin-bottom: 8px;
}
.sunset-moonset .alert {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 26px;
  margin-bottom: 24px;
}
.sunset-moonset .alert-warning {
  background-color: #FDE8DF;
  border-color: #FC5910;
}
.sunset-moonset .alert-warning .sunset-icon {
  background-color: rgba(255, 255, 255, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #FC5910;
  padding: 8px;
}
.sunset-moonset .alert-info {
  background-color: #EDF2FF;
  border-color: #10A7FC;
}
.sunset-moonset .alert-info .moon-icon {
  background-color: rgba(255, 255, 255, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #10A7FC;
  padding: 8px;
}

.panchang-table table, .planetary-table table, .muhurt-table table {
  margin: 0px;
  border-radius: 4px;
}
.panchang-table table tbody, .planetary-table table tbody, .muhurt-table table tbody {
  border-radius: 4px;
  border: 1px solid #CED4DA;
}
.panchang-table table tbody tr:nth-of-type(odd) td, .planetary-table table tbody tr:nth-of-type(odd) td, .muhurt-table table tbody tr:nth-of-type(odd) td {
  background-color: #FAF4F0;
  --bs-table-bg-type: none ;
}
.panchang-table table tbody tr td, .planetary-table table tbody tr td, .muhurt-table table tbody tr td {
  border: 0px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 500;
  color: #1E1E1E;
  width: 50%;
}

.muhurt-box {
  background-color: #fff;
  padding: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  clear: both;
}
.muhurt-box .muhurt-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(30, 30, 30, 0.7);
  padding: 20px 0px 12px;
  margin: 0px;
}

.muhurt-table table tbody tr td {
  width: auto;
  min-width: 125px;
  vertical-align: middle;
}

.box-align {
  align-items: center;
}

.lagan-chart {
  background-color: #fff;
  padding: 25px 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
}

.planetary-table table thead tr th {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.planetary-table table tbody tr td {
  width: auto;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .location-box form .theme-btn {
    padding: 12px;
  }
  .sunset-moonset .alert.alert-info {
    margin-bottom: 0px;
  }
  .muhurt-box {
    height: auto;
  }
  .muhurt-table table tbody tr td {
    min-width: 100px;
  }
}/*# sourceMappingURL=panchang.css.map */