@import "./../../variables";

.horoscope-list {
    margin: 0px;
    padding: 16px 12px;
    background-color: $white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .horo-list-card {
        position: relative;
        box-shadow: 2px 2px 10px 0px rgba($black, $alpha: 0.15);

        .MuiCardContent-root {
            padding: 0px;

            .card-content-inner {
                padding: 16px 16px 0px 16px;

                .horo-icon-1x1 {
                    width: 100px;
                    height: 100px;
                    border-radius: 100%;
                    background-color: $headingColor;
                    text-align: center;
                    display: flex;
                    padding: 16px;
                    margin-bottom: 24px;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }

                h5 {
                    font-size: 16px;
                    font-weight: $fontWeight-600;
                    line-height: 19px;
                    color: $black;
                    margin-bottom: 8px;
                    letter-spacing: 0px;
                }

                h6 {
                    font-size: 14px;
                    font-weight: $fontWeight-400;
                    line-height: 16px;
                    color: $defaultColor;
                    margin-bottom: 24px;
                    letter-spacing: 0px;
                }

                p {
                    font-size: 14px;
                    font-weight: $fontWeight-400;
                    line-height: 22px;
                    color: $defaultColor;
                    text-align: justify;
                    margin-bottom: 24px;
                    color: $headingColor;
                    letter-spacing: 0px;

                    button {
                        font-size: 14px;
                        font-weight: $fontWeight-700;
                        color: $textLink;
                        text-transform: capitalize;
                        padding: 0px 0px 0px 5px;
                        line-height: 22px;
                        letter-spacing: 0px;
                    }
                }
            }

            .horo-card-lists {
                position: relative;
                border-radius: 0px;

                .list-group-item {
                    padding: 13px 14px;
                    border-radius: 0px;
                    border-bottom: 1px solid #CED4DA;
                    border-left: 0px;
                    border-right: 0px;

                    &:last-child {
                        border-bottom: 0px;
                    }

                }
            }
        }
    }
}