@import "./../astrologyVideo/astrologyVideoCard.scss";

.astrovideo-list-wrap {
    background: $white;
    padding: 24px 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .heading-title {
        font-size: 24px;
        font-weight: $fontWeight-700;
        line-height: 30px;
        padding-bottom: 24px;
        letter-spacing: 0px;
        color: $headingColor;
    }
}

.modal-closebtn {
    color: $white;

    svg {
        color: $white;

    }

    &:hover {
        svg {
            color: $black;
        }
    }
}