@import './../../variables';

.blog-section {
    background-color: $white;
    border-radius: 4px;
    gap: 24px;
    padding: 24px 0px;

}


.blog-card {
    padding: 0px;
    border: 1px solid rgba($black, $alpha: 0.20);
    box-shadow: none !important;

    .blog-card-content {
        padding: 0px;
        position: relative;

        &:last-child {
            padding-bottom: 0px;
        }

        &:hover {
            .blog-cardbody {
                .blog-card-title {
                    color: $textLink;
                }
            }
        }

        img {
            aspect-ratio: 4/2.7;
            object-fit: cover;
            width: 100%;
            height: auto;
        }

        .blog-cardbody {
            padding: 12px;
            background-color: $white;

            .blog-card-title {
                font-family: $defaultFont, sans-serif;
                font-size: 15px;
                line-height: 19px;
                font-weight: $fontWeight-400;
                text-align: justify;
                color: $headingColor;
                padding-bottom: 12px;
                margin: 0px;
                transition: all ease 0.6;
            }

            .date-author-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                line-height: 19px;
                font-weight: $fontWeight-400;
                color: $defaultColor;
            }
        }

        .card-overlay {
            position: absolute;
            right: 0;
            top: 0;
            background: rgba($white, $alpha: 0.70);
            padding: 8px 12px;
            border-bottom-left-radius: 4px;
            display: inline-flex;
            align-items: center;
            gap: 4px;
            font-size: 12px;
            font-family: $defaultFont, sans-serif;
            color: $black;
            font-weight: $fontWeight-600;
            line-height: 14px;

            svg {
                font-weight: $fontWeight-400;
                font-size: 12px;
            }
        }
    }

}