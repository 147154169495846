@import "./../../variables";

.kundali-tab.nav-tabs {
    .nav-link {
        color: $black;

        &.active {
            color: $textLink;
        }


    }
}

.free-kundali-contents {
    padding: 16px 12px 0px;

    .table-responsive {
        &:nth-child(2) {
            margin-top: 13px;
        }
    }

    table {


        thead {
            tr {
                th {
                    border: 0px;
                    font-family: $defaultFont, sans-serif;
                    font-weight: $fontWeight-500;
                    font-size: 18px;
                    color: $headingColor;
                    line-height: 27px;
                }
            }
        }

        tbody {
            border: 1px solid #CED4DA;
            border-radius: 4px;

            tr {
                &:nth-of-type(odd) {
                    td {
                        background-color: $bg-lightOrange !important;
                        --bs-table-bg-type: none;
                    }
                }

                td {
                    border: 0px;
                    font-family: $defaultFont, sans-serif;
                    font-size: 14px;
                    color: $headingColor;
                    font-weight: $fontWeight-500;
                    line-height: 21px;

                    &:empty {
                        display: none;
                    }
                }


            }
        }

    }
}

@media (max-width: 767px) {
    .kundali-tab.nav-tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        .nav-link {
            padding: 12px 30px;
        }
    }

    .free-kundali-contents {
        .table-responsive {
            &:nth-child(2) {
                margin-top: 0px;
            }
        }

        table {
            margin-bottom: 0px;
        }
    }
}