@import "./../../variables";

.compatibility-section {
    background-color: $white;
    border-radius: 4px;
    padding: 16px 12px;
}

.compatibility {
    .title {
        font-size: 20px;
        margin: 17px 0px 12px;
        line-height: 24px;
        color: $headingColor;
        font-weight: $fontWeight-700;
    }

    &:nth-child(n+1) {
        .compatibility-box {
            border-color: #FFA6A6;
            background-color: #FAF1F0;
        }
    }

    &:nth-child(n+2) {
        .compatibility-box {
            border-color: rgba(#21BD60, $alpha: 0.5);
            background-color: #E4FFEF;
        }
    }

    &:nth-child(n+3) {
        .compatibility-box {
            border-color: rgba(#A586FF, $alpha: 0.5);
            background-color: #F4F0FF;
        }
    }

    &:nth-child(n+4) {
        .compatibility-box {
            border-color: rgba(#3EA9F5, $alpha: 0.5);
            background-color: #EBF7FF;
        }
    }
}

.compatibility-box {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px;
    background-color: #FAF1F0;
    border-width: 1px;
    border-style: solid;
    border-color: #FFA6A6;
    border-radius: 10px;
    margin-bottom: 32px;

    p {
        width: calc(100% - 100px);
        margin: 0px;
        padding-right: 40px;
        font-size: 15px;
        line-height: 24px;
        font-weight: $fontWeight-400;
        color: $defaultColor;
    }

    .circle-box {
        width: 100px;
        height: 100px;
        font-weight: $fontWeight-700;
        line-height: 27px;
    }

}

.relationship-tip {
    .title {
        margin-top: 0px;
    }

    p {
        font-size: 15px;
        line-height: 24px;
        text-align: justify;
        font-weight: $fontWeight-400;
        color: $defaultColor;
        margin: 0px;
    }
}

@media (max-width: 767px) {
    .compatibility-box {
        p {
            width: 100%;
            margin: 0px;
            padding-right: 0px;
            order: 2;
            font-size: 14px;
            text-align: justify;
        }

        .circle-box {
            width: 70px;
            height: 70px;
            margin: 0 auto 16px;
        }
    }
}