/* colors */
/* Fonts */
header {
  margin: 0px;
  margin-bottom: 16px;
  padding: 8px 12px;
  height: 150px;
  position: relative;
  background-color: #fff;
}
header .header-top {
  height: 80px;
  overflow: hidden;
}
header .header-top .MuiGrid-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.main-menu {
  min-height: 54px;
  margin-top: 8px;
}
.main-menu .menu-wrap {
  display: flex;
  align-items: center;
  max-width: calc(100% - 142px);
}
.main-menu .navbar {
  padding: 8px 0px 0px;
}
.main-menu .navbar .navbar-collapse {
  z-index: 9;
  background-color: #fff;
}
.main-menu .navbar .nav-link {
  padding: 6px 12px;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-transform: capitalize;
}
.main-menu .navbar .nav-link:hover {
  color: #FC5910;
  transition: all 0.5s;
}
.main-menu .navbar .dropdown-menu {
  border: 0px;
}
.main-menu .navbar .dropdown-menu .dropdown-item {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.main-menu .navbar .dropdown-menu .dropdown-item:hover, .main-menu .navbar .dropdown-menu .dropdown-item:focus, .main-menu .navbar .dropdown-menu .dropdown-item:active {
  background-color: #FC5910;
  color: #fff;
}

.search-box {
  position: relative;
}
.search-box .MuiInputBase-root {
  padding-left: 16px;
  border-radius: 4px;
  background-color: #F7F7F7;
}
.search-box .MuiInputBase-root:hover {
  border-bottom: 0px;
}
.search-box .MuiInputBase-root:hover::before {
  border-bottom: 0px !important;
}
.search-box .MuiInputBase-root::before {
  border-bottom: 0px;
}
.search-box .MuiInputBase-root::after {
  border-bottom: 0px;
  border-bottom-color: transparent;
}
.search-box .MuiInputAdornment-root {
  margin-top: 0px !important;
}
.search-box .MuiInputAdornment-root svg {
  color: #FC5910;
}
.search-box .MuiInputBase-input {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 46px;
}

.social-icons {
  display: block;
  position: relative;
  margin: 0px;
  padding: 0px;
  float: right;
}
.social-icons li {
  list-style: none;
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-left: 12px;
  border-radius: 100%;
}
.social-icons li:first-child {
  margin-left: 0px;
}
.social-icons li a {
  display: block;
}
.social-icons li a img {
  width: 100%;
  height: 100%;
}

.header-login-btn {
  position: absolute;
  right: 16px;
  bottom: 8px;
}

@media (max-width: 992px) {
  .main-menu {
    width: 100%;
  }
  .main-menu .menu-wrap {
    max-width: 100%;
    display: block;
  }
  .main-menu .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  .main-menu .navbar .navbar-collapse {
    margin-left: -12px;
    margin-right: -28px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.4);
  }
}
@media (max-width: 767px) {
  .header-login-btn {
    right: 12px;
  }
}/*# sourceMappingURL=headerComponents.css.map */