/* colors */
/* Fonts */
.kundali-match-report {
  background-color: #fff;
  padding: 24px 12px 0px;
  margin-top: 16px;
  clear: both;
  border-radius: 4px;
}
.kundali-match-report .heading-title {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  line-height: 30px;
  color: #1E1E1E;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.kundali-match-report .kundali-matcher {
  margin: 24px auto;
  text-align: center;
  display: block;
}
.kundali-match-report .kundali-matcher .bg-danger {
  background-color: #FC5910 !important;
  color: #fff !important;
}
.kundali-match-report .kundali-matcher .badge {
  border-radius: 12px;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 24px;
  letter-spacing: 0px;
}
.kundali-match-report .kundali-matcher span {
  display: inline-block;
  margin: 0px 16px;
}

#egg {
  display: flex;
  width: 336px;
  height: 295px;
  background-color: rgba(253, 253, 253, 0.1490196078);
  border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
  align-items: center;
  justify-content: center;
}

.kundali-match-table {
  position: relative;
}
.kundali-match-table table {
  border: 1px solid #CED4DA;
  border-radius: 4px;
  margin: 0px;
}
.kundali-match-table table thead tr {
  background-color: #FAF4F0;
}
.kundali-match-table table thead tr th {
  border: 0px;
  background-color: #FAF4F0;
  color: #1E1E1E;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding: 6px 14px;
}
.kundali-match-table table thead tr th img {
  height: 25px;
  margin-right: 12px;
  width: auto;
}
.kundali-match-table table tbody tr {
  border-color: #CED4DA;
}
.kundali-match-table table tbody tr td {
  color: #1E1E1E;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding: 10px 14px;
  text-transform: capitalize;
}

.kundali-avakhada-table {
  margin-top: 36px;
}
.kundali-avakhada-table .title {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
  opacity: 70%;
}
.kundali-avakhada-table table {
  border: 1px solid #CED4DA;
  border-radius: 4px;
}
.kundali-avakhada-table table thead tr {
  background-color: #FFCFA4;
}
.kundali-avakhada-table table thead tr th {
  background-color: #FFCFA4;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 16px;
}
.kundali-avakhada-table table tbody tr td {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #1E1E1E;
  padding: 24px 10px;
  border-bottom: 0px;
}
.kundali-avakhada-table table tbody tr:nth-of-type(odd) td {
  background-color: #FAF4F0;
  --bs-table-bg-type: none;
}
.kundali-avakhada-table table tr td,
.kundali-avakhada-table table tr th {
  text-align: center;
  min-width: 125px;
  vertical-align: middle;
  border-left: 1px solid #CED4DA;
}

.dosha-title {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}

.kundali-ashtakoot {
  position: relative;
}
.kundali-ashtakoot .alert-danger {
  background-color: #FAF1F0;
  border-color: #FFA6A6;
  border-radius: 12px;
  padding: 24px;
}
.kundali-ashtakoot .alert-success {
  background-color: #E4FFEF;
  border-color: #36DA77;
  border-radius: 12px;
  padding: 24px;
}
.kundali-ashtakoot .asashtakoot-box {
  display: flex;
  gap: 24px;
  align-items: center;
}
.kundali-ashtakoot .asashtakoot-box h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}
.kundali-ashtakoot .asashtakoot-box .CircularProgressbar .CircularProgressbar-text {
  text-transform: capitalize;
}

.astroconsult-section {
  background-color: #FC5910;
  border-radius: 4px;
  padding: 16px 12px 0px;
}
.astroconsult-section h4,
.astroconsult-section p {
  color: #fff;
}
.astroconsult-section h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 12px;
}
.astroconsult-section p {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  padding-bottom: 24px;
}

@media (max-width: 767px) {
  .kundali-match-report .kundali-matcher {
    margin: 16px auto;
  }
  .kundali-match-report .kundali-matcher .bg-danger {
    background-color: #FC5910 !important;
    color: #fff !important;
  }
  .kundali-match-report .kundali-matcher .badge {
    border-radius: 12px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 24px;
    letter-spacing: 0px;
    display: block;
  }
  .kundali-match-report .kundali-matcher span {
    display: block;
    margin: 16px;
  }
}/*# sourceMappingURL=kundaliMatchReport.css.map */