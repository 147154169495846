@import "./../../variables";

footer {
    background-color: $bg-lightOrange;
}

.footer {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 16px;
}

.footer-heading {
    position: relative;
    font-family: $defaultFont, sans-serif !important;
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: $fontWeight-600 !important;
    color: $headingColor !important;
    padding-bottom: 10px;
    margin-bottom: 28px !important;
    text-transform: capitalize;

    &::before {
        content: "";
        width: 60px;
        height: 2px;
        background-color: $primaryColor;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.f-about-dtl {
    font-size: 17px !important;
    line-height: 24px !important;
    text-align: justify;
    color: $defaultColor !important;
    padding-bottom: 24px;
}

.footer-logos {
    margin: 0px;

    .footer-playStore {
        margin-top: 32px;
        width: 100%;

        img {
            margin-bottom: 12px;
            box-shadow: 0px 24px 45px 0px #7F8DA333;
            max-width: 100%;
            height: auto;
        }
    }
}

.f-navbar {
    position: relative;
    width: 100%;

    ul {
        margin: 0px;
        padding: 0px;

        li {
            list-style: none;
            display: block;
            padding-bottom: 20px;
            font-family: $defaultFont, sans-serif;
            font-size: 16px;
            line-height: 20px;
            text-transform: capitalize;
            color: $headingColor;

            span {
                width: 18px;
                margin-right: 8px;
                float: left;

                svg {
                    font-size: 18px;
                }
            }

            a {
                font-family: $defaultFont, sans-serif;
                font-size: 16px;
                line-height: 19px;
                font-weight: $fontWeight-400;
                color: $headingColor;
                transition: all ease 0.5s;

                &:hover,
                &:focus {
                    color: $textLink;
                }
            }
        }
    }
}

.footer-bottom {
    margin-top: 40px;
    background-color: $headingColor;
    padding: 16px;
    width: 100%;
    p{margin: 0px; font-size: 16px; color: $white; line-height: 24px; text-align: center;}
}