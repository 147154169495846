@import "./../../variables";

.blog-details-wrap {
    background-color: $white;
    border-radius: 4px;
    padding: 24px 12px;

    .heading-wtbg {
        text-align: left;
    }
}

.blog-details {
    .hero-img {
        margin-bottom: 24px;

        img {
            width: 100%;
            height: auto;
            border-radius: 4px;
            aspect-ratio: 16/9;
            object-fit: contain;
        }
    }

    .blog-social-wrap {
        margin-bottom: 24px;
        display: block;

        .social-icons {
            float: none;
        }
    }
}

.synopsis {
    margin-bottom: 24px;

    p {
        font-size: 15px;
        font-weight: $fontWeight-400;
        line-height: 24px;
        color: $defaultColor;
        text-align: justify;
    }
}

.blog-app-banner {
    margin-bottom: 24px;

    a {
        img {
            border-radius: 4px;
            width: 100%;
            height: auto;
            aspect-ratio: 4.13/1;
            object-fit: contain;
        }
    }
}

.blog-details-body {
    .body-heading {
        font-size: 20px;
        font-weight: $fontWeight-700;
        line-height: 32px;
        color: $black;
    }

    p {
        font-size: 15px;
        font-weight: $fontWeight-400;
        line-height: 24px;
        color: $black;
    }

    .body-sub-heading {
        font-size: 16px;
        font-weight: $fontWeight-600;
        line-height: 32px;
        color: $black;
    }

    a {
        color: $textLink;
        box-shadow: $textLink 0px -1px 0px inset;
        transition: all 0.6s;

        &:hover {
            color: $primaryColor;
            box-shadow: $primaryColor 0px -1px 0px inset;
        }
    }
}