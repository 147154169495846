/* colors */
/* Fonts */
.astrologer-sections {
  background-color: #FAF4F0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.astrologer-sections .slick-track {
  margin: 0px !important;
  display: flex;
  flex-wrap: nowrap;
}
.astrologer-sections .slick-slide {
  padding: 12px 6px;
  max-width: 315px !important;
  width: 100% !important;
}
.astrologer-sections .slick-prev,
.astrologer-sections .slick-next {
  z-index: 9;
  background-color: #fff !important;
  color: #1E1E1E !important;
  width: 40px;
  height: 76px;
  text-align: center;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3019607843);
}
.astrologer-sections .slick-prev svg,
.astrologer-sections .slick-next svg {
  height: 100%;
}
.astrologer-sections .slick-prev.slick-disabled,
.astrologer-sections .slick-next.slick-disabled {
  color: rgba(0, 0, 0, 0.4) !important;
}
.astrologer-sections .slick-prev {
  left: 0;
}
.astrologer-sections .slick-next {
  right: 0;
}
.astrologer-sections .slick-prev:before,
.astrologer-sections .slick-next:before {
  content: "";
}

.astrologer-card {
  position: relative;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15) !important;
  background-color: #fff;
}
.astrologer-card .astro-card-content {
  padding: 16px !important;
}
.astrologer-card .astro-card-content a {
  display: flex;
  justify-content: space-between;
  gap: 13px;
}
.astrologer-card .astro-card-content .align-text {
  align-items: flex-end;
  display: flex;
  gap: 5px;
}
.astrologer-card .astro-card-content .card-body-text h5 {
  margin: 0px;
  font-family: "Inter";
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.astrologer-card .astro-card-content .card-body-text p {
  margin: 0px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #707070;
  padding-bottom: 6px;
}
.astrologer-card .astro-card-content .astro-price {
  display: block;
  margin-bottom: 6px;
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: #707070;
  text-align: center;
}
.astrologer-card .astro-card-content .astro-btn {
  font-size: 10px;
  font-family: "Inter";
  color: #FC5910 !important;
  border-color: #FC5910 !important;
  padding: 8px !important;
}/*# sourceMappingURL=astrologer.css.map */