/* colors */
/* Fonts */
.horoscope-list {
  margin: 0px;
  padding: 16px 12px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.horoscope-list .horo-list-card {
  position: relative;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.horoscope-list .horo-list-card .MuiCardContent-root {
  padding: 0px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .card-content-inner {
  padding: 16px 16px 0px 16px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .card-content-inner .horo-icon-1x1 {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #1E1E1E;
  text-align: center;
  display: flex;
  padding: 16px;
  margin-bottom: 24px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .card-content-inner .horo-icon-1x1 img {
  display: block;
  width: 100%;
  height: auto;
}
.horoscope-list .horo-list-card .MuiCardContent-root .card-content-inner h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #000000;
  margin-bottom: 8px;
  letter-spacing: 0px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .card-content-inner h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #707070;
  margin-bottom: 24px;
  letter-spacing: 0px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .card-content-inner p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #707070;
  text-align: justify;
  margin-bottom: 24px;
  color: #1E1E1E;
  letter-spacing: 0px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .card-content-inner p button {
  font-size: 14px;
  font-weight: 700;
  color: #D34100;
  text-transform: capitalize;
  padding: 0px 0px 0px 5px;
  line-height: 22px;
  letter-spacing: 0px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .horo-card-lists {
  position: relative;
  border-radius: 0px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .horo-card-lists .list-group-item {
  padding: 13px 14px;
  border-radius: 0px;
  border-bottom: 1px solid #CED4DA;
  border-left: 0px;
  border-right: 0px;
}
.horoscope-list .horo-list-card .MuiCardContent-root .horo-card-lists .list-group-item:last-child {
  border-bottom: 0px;
}/*# sourceMappingURL=horoscopeList.css.map */