@import "./../../variables";

.horoscope-tab {
    position: relative;
    background-color: $white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .nav-tabs {
        border: transparent;

        .nav-link {
            border: 1px solid rgba($black, $alpha: .20);
            background-color: $white;
            padding: 12px 10px;
            border-radius: 0px;
            color: $black;
            font-family: $defaultFont;
            font-size: 16px;
            font-weight: $fontWeight-600;

            &.active,
            &:hover,
            &:focus {
                background-color: $bg-lightOrange;

            }
        }
    }

    .tab-content {
        padding: 0px 0px 16px;
    }
}

.horoscope-lists {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;

    li {
        list-style: none;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-content: center;
        width: 110px;
        height: 90px;
        border: 1px solid rgba($black, $alpha: .20);
        border-left: 0px;

        button {
            width: 100%;
        }

        &.active,
        &:hover,
        &:focus {
            background-color: $bg-lightOrange;
        }
    }
}

.horoscope-contents {
    padding: 16px 12px 0px;

    .htitle-date {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;

        h2 {
            font-family: $defaultFont;
            font-size: $fontSize-24;
            font-weight: $fontWeight-500;
            color: $black;
        }

        h6 {
            color: $primaryColor;
            font-family: $defaultFont;
            font-weight: $fontWeight-600;
        }
    }

    .horoscope-p {
        margin: 0px;
        font-family: $defaultFont;
        font-size: 14px;
        font-weight: $fontWeight-400;
        line-height: 22px;
        text-align: justify;

        button {
            text-transform: capitalize;
            font-size: 14px;
            font-weight: $fontWeight-700;
            color: $primaryColor;
            background-color: transparent !important;
            padding: 0px 5px !important;
        }
    }
}

@media (max-width: 767px) {
    .horoscope-contents {
        .htitle-date {
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;

            h2 {
                font-size: 20px;
            }

            h6 {
                font-size: 14px;
            }
        }
    }
}