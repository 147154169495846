@import "./../../variables";

.festival-details-wrap {
    background-color: $white;
    border-radius: 4px;
    padding: 24px 12px;

    .synopsis {
        margin-bottom: 0px;

        p {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}

.festival-details-body {
    margin-bottom: 24px;

    .title {
        font-size: 20px;
        line-height: 32px;
        color: $black;
        font-weight: $fontWeight-700;
        margin-bottom: 24px;

        &:empty {
            display: none;
        }
    }

    p {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 24px;
        font-weight: $fontWeight-400;
        text-align: justify;
        color: $black;
    }

    ul,
    ol {
        margin: 0px 0px 24px;
        padding-left: 20px;

        &:has(li:empty) {
            display: none;
        }

        li {
            font-size: 15px;
            line-height: 30px;
            color: $black;
            font-weight: $fontWeight-400;

            &:empty {
                display: none;
            }
        }
    }
}