/* colors */
/* Fonts */
.static-page-bg {
  background-color: #fff;
  padding: 20px 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.static-page-box h1 {
  font-size: 26px;
  font-weight: 700;
}
.static-page-box h2 {
  font-size: 24px;
  font-weight: 700;
}
.static-page-box h3 {
  font-size: 22px;
  font-weight: 600;
}
.static-page-box h4 {
  font-size: 20px;
  font-weight: 600;
}
.static-page-box h5 {
  font-size: 18px;
  font-weight: 500;
}
.static-page-box h6 {
  font-size: 16px;
  font-weight: 500;
}
.static-page-box h1,
.static-page-box h2,
.static-page-box h3,
.static-page-box h4,
.static-page-box h5,
.static-page-box h6 {
  color: #1E1E1E;
  margin-bottom: 12px;
}
.static-page-box p {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #707070;
  text-align: justify;
  margin-bottom: 20px;
}
.static-page-box img {
  height: auto;
  max-width: 100%;
  aspect-ratio: 16/9;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
}
.static-page-box .about-us-heroImg {
  position: relative;
  width: 100%;
  height: 600px;
}
.static-page-box .about-us-heroImg .overlay {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  background-color: #1E1E1E;
  padding: 0px 12px;
  height: 128px;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.static-page-box .about-us-heroImg .overlay ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 128px;
}
.static-page-box .about-us-heroImg .overlay ul li {
  list-style: none;
  text-align: center;
  position: relative;
  border-left: 1px solid #F7F7F7;
  padding: 0px 50px;
}
.static-page-box .about-us-heroImg .overlay ul li:first-child {
  border-left: 0px;
  padding-left: 0px;
}
.static-page-box .about-us-heroImg .overlay ul li:last-child {
  padding-right: 0px;
}
.static-page-box .about-us-heroImg .overlay ul li h3 {
  color: #FC5910;
  margin-bottom: 12px;
  padding: 0px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
}
.static-page-box .about-us-heroImg .overlay ul li span {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
}

@media (max-width: 767px) {
  .static-page-box .about-us-heroImg {
    position: relative;
    width: 100%;
    height: auto;
  }
  .static-page-box .about-us-heroImg .overlay {
    position: relative;
    bottom: 40px;
    height: auto;
    padding: 16px;
  }
  .static-page-box .about-us-heroImg .overlay ul {
    height: auto;
  }
  .static-page-box .about-us-heroImg .overlay ul li {
    padding: 0px 16px;
  }
  .static-page-box .about-us-heroImg .overlay ul li h3 {
    font-size: 22px;
    line-height: 22px;
  }
  .static-page-box .about-us-heroImg .overlay ul li span {
    font-size: 13px;
  }
}
@media (max-width: 400px) {
  .static-page-box .about-us-heroImg .overlay ul {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .static-page-box .about-us-heroImg .overlay ul li {
    padding: 0px 8px;
  }
  .static-page-box .about-us-heroImg .overlay ul li h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .static-page-box .about-us-heroImg .overlay ul li span {
    font-size: 13px;
  }
}/*# sourceMappingURL=staticPages.css.map */